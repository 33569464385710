body {
    overflow-x: hidden !important;
    width: 100vw !important;
}

.side-menu-item {
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 0.01rem solid grey;
    list-style-type: none;


}

.input-fields-style {
    width: 90%;
    padding: 10px;
    background-color: #eaeaea;
    border-radius: 5px;
}

.label-style {
    display: block;
    font-weight: 500;

}

.side-menu-container {
    border-right: 0.01rem solid grey;
    padding-right: 0 !important;
    height: 98vh;

}

.side-menu-item-acc {
    border-bottom: 0.01rem solid grey;
    border-right: none;
}

.dash-card {
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 10px;
}

.dash-content-section {
    margin-bottom: 5rem;
}

a {
    text-decoration: none !important;
    color: black;
}

/* Table */
h2 {
    color: #fff;
    font-size: 20px;

}

.hidden {
    display: none;
}

.custom-table {
    /* min-width: 900px;
     */
    border-radius: 10px !important;
}

.custom-table thead tr,
.custom-table thead th {
    padding-bottom: 30px;
    border-top: none;
    border-bottom: none !important;

    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .2rem;
}

.custom-table tbody th,
.custom-table tbody td {
    color: #777;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 300;
    border: none;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.custom-table tbody th small,
.custom-table tbody td small {
    color: rgba(255, 255, 255, 0.3);
    font-weight: 300;
}

.custom-table tbody th a,
.custom-table tbody td a {
    /* color: rgba(255, 255, 255, 0.3); */
}

.custom-table tbody th .more,
.custom-table tbody td .more {
    color: rgba(255, 255, 255, 0.3);
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .2rem;
}

.custom-table tbody tr {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.custom-table tbody tr:hover td,
.custom-table tbody tr:focus td {}

.custom-table tbody tr:hover td a,
.custom-table tbody tr:focus td a {
    color: #fdd114;
}

.custom-table tbody tr:hover td .more,
.custom-table tbody tr:focus td .more {
    color: #fdd114;
}

.custom-table .td-box-wrap {
    padding: 0;
}

.custom-table .box {
    background: #fff;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.custom-table .box td,
.custom-table .box th {
    border: none !important;
}

.content {
    background-color: #3c373e;
    padding: 2rem;
    border-radius: 10px;
    margin: 10px;
}

.formwrap {
    background-color: #d9d9d9;
    padding: 2rem;
    border-radius: 1rem;
}

.formwraptable {
    overflow-y: scroll !important;
    width: 75vw;
    background-color: #f1f1f1;
    padding: 1rem;
    border-radius: 1rem;
}

table {
    background-color: #fff !important;
}

.table-elements {
    border: 1px solid black;
    padding: 5px;
}

.head {
    font-weight: bold !important;
}