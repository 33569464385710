.mySwiper2 .swiper {
    width: 100%;
    height: 100%;
}

.mySwiper2 .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper2 .swiper-slide img {
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover !important;
}