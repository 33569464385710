* {

  font-family: 'Poppins';
  -webkit-text-size-adjust: none !important;


}


.extra-bed {
  padding-left: 5rem;
  font-size: 12px;
}

.services-cards img {
  min-width: 100% !important;
  height: auto;
}

input[value=""]::-webkit-datetime-edit {
  color: transparent;
}

input:focus::-webkit-datetime-edit {
  color: #000;
}

input {
  outline: none;
  width: 100%;
}

body {
  width: 100vw !important;
  overflow-x: hidden !important;
  -webkit-text-size-adjust: none !important;
}

:root {
  width: 100vw !important;
  overflow-x: hidden !important;
  -webkit-text-size-adjust: none !important;

}

.select-style-fix {
  -webkit-text-size-adjust: none !important;
}

#adultPrice46 {
  -webkit-text-size-adjust: none !important;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-right: var(--bs-navbar-nav-link-padding-x);
  line-height: 40px;
}

p {
  margin: 0;
  margin-top: 0px;
}

.header-nav {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.sign-up-btn {
  background-color: #FFCC00 !important;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.search-button {
  background-color: #FFCC00 !important;

  border-radius: 1.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-weight: bold;
  color: #0A3874 !important;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.modal-login-button {
  background-color: #FFCC00 !important;
  width: 100% !important;
  border-radius: 1.5rem;

  font-weight: bold;
  color: #0A3874 !important;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.booking-banner {
  /* background-image: url("/public/assets/img/image1.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 40rem;
  overflow-y: hidden;


}

/* modal spiner */
.spinner-modal .modal-content {
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  height: 50vh !important;
  width: 40vw !important;

}

.navbar-toggler-icon {
  /* background-image: url(../public/assets/img/cross-nav.png); */
}

.collapsed.navbar-toggler-icon {
  background-image: var(--bs-navbar-toggler-icon-bg) !important;
}

.blogs-wrapper {
  background-image: url("/public/assets/img/blogs-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem;
  width: 100vw;
}

.booking-banner-title {
  font-size: 4rem !important;
  /* margin: 0px 50px 0px 50px; */
  color: white;
  font-weight: 800;
  line-height: 5.2rem;

}

.booking-banner-title span {
  color: #FFCC00;
}

.booking-form {
  max-width: 35rem;
  padding: 1.5rem !important;
  border-radius: 1rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: 4rem !important;
}

.form-element-style {
  border: none !important;
}

.form-element-each {
  border: 0.50px #ADADAD solid;
  padding: 0.5rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border-radius: 1rem;

}

.form-element-each span {
  display: block;
  margin-left: 0.7rem;
  color: #797979 !important;
}

.form-element-each.check-in {
  border: 0.50px #ADADAD solid;
  padding: 0.5rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;

}

.form-element-each.check-out {
  border: 0.50px #ADADAD solid;
  padding: 0.5rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;

  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.instyle {
  margin-top: 20px !important;
  margin-bottom: 20px !important;



}

.instyle label {
  font-weight: bold !important;
  color: #072852;
}

.tabscont {
  /* width: 105% !important; */
}

.tab-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ADADAD !important;
  padding: 0.1rem !important;

}

.tab-heads {
  display: flex;
  justify-content: space-between;
}

.bord-btm-yellow :hover {
  border-bottom: 4px solid #FFCC00 !important;
}

.nav-link.active {
  border-bottom: 4px solid #FFCC00 !important;
  color: #000 !important;
  font-weight: 400;
}

.welcome-section {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.welcome-section h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.intro {
  color: #000 !important;
  font-size: 1.3rem !important;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
  /* text-align: justify; */
}

.event-intro {
  font-size: 1.3rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.welcomecards h5 {
  font-weight: bolder;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.welcomecards p {
  color: #797979;
}

.welcomecards {
  overflow-x: hidden;
}

.blogs-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.blogs-section h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.blog-intro {
  font-size: 1.3rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.blogs-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.blogs-cards p {
  color: #797979;
}

.events-wrapper {
  width: 100vw !important;
  background-color: #FFEFB0;
  padding: 1rem
}

.events-section {
  margin-top: 4rem;
  margin-bottom: 4rem;

}

.events-section h2 {
  color: #6B5600 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.events-intro {
  color: #383838;
  font-size: 1.3rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.events-cards {
  /* margin-right: 6.5rem; */
}

.events-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.events-cards p {
  color: #797979;
}

.experience-wrapper {
  width: 100vw !important;
  background-color: #061F40;
  padding: 1rem;
  position: relative;

}

.experience-section {
  margin-top: 4rem;
  margin-bottom: 4rem;

}

.experience-cards {
  background-color: #E7EBF1;
  border-radius: 1rem;
  padding: 1rem;

}

.experince-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}

.experience-section h2 {
  color: #E7EBF1 !important;
  font-size: 3rem !important;
  font-weight: bolder;
  margin-bottom: 2rem;
}

.experience-intro {
  color: #383838;
  font-size: 1.3rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.experience-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.experience-cards p {
  color: #797979;
}

.cards-inner-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.review-text {
  padding: 1rem;
  padding-bottom: 1rem;
}

.see-more-btn {
  background-color: transparent;
  color: #FFCC00;
  margin: 2rem;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: 2px solid #FFCC00;
  border-radius: 2rem;
  font-weight: 700;
}

.see-more-btn:hover {
  color: #0A3874;
  background-color: #FFCC00;
  border-color: #FFCC00;
}

/* .partnership h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

} */

.partnership {
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
  background-color: #fff;

  width: 100vw;
}

.cta-wrapper {

  background-image: url("/public/assets/img/cta-bg-web.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0px -600px; */
  padding: 1rem;
  width: 100vw;
}

.subscribe-btn {
  background-color: #FFCC00 !important;
  font-weight: bolder;
  border-radius: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;

  display: inline-block;
}

.stay-connect span {
  font-size: 2.4rem !important;
  margin: 0rem 1rem 0rem 1rem;
  color: white;
  font-weight: bolder;
  line-height: 4rem;
  text-align: start;
  display: block;
}

.cta {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.sub-row-cont {
  background-color: #fff;
  border-radius: 2.5rem;
  padding-left: 0.2rem !important;
  padding-right: 3rem !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.email-input :focus {
  outline: none;
}

.email-input {
  border: none;
}

.footer-wrapper {
  padding-top: 2rem;
  /* padding-bottom: 5rem; */
  background-color: #061F40;
  width: 100vw;
  position: relative;
}

.footer-row {
  justify-content: space-evenly;
}

.copright-txt {
  margin-top: 0.5rem;
  color: #fff;
}

.liststyles {
  text-align: left;
}

.liststyles ul li {
  list-style-type: none;
  text-decoration: none;
  color: #fff;
  padding: 0.2rem;
}

.liststyles span {
  color: #fff;
}

.payment span {
  color: white;
  display: block;
}

.pay-icon {
  margin: 0.2rem;
}

.list-head {
  font-weight: bold !important;
  font-size: 1.2rem;
}

.slides {
  display: flex;
  justify-content: center;
  width: 200px !important;
}

.tab-item-img {
  width: 35px;
}

.tab-item-img:hover {
  border: none !important;
}

#tab1-img:hover {
  border: none !important;
}

/* Styles for desktop view */
.sticky-bottom-navbar {
  display: none;
}

.sticky-bottom-navbar2 {
  display: none;
}

.tab-item-img1 {
  display: block;
}

.tab-item-img2 {
  display: none;
}

.nav-link.active:focus {
  border-bottom: 4px solid #FFCC00 !important;
  color: #000 !important;
  font-weight: 400;
}

.bord-btm-yellow :hover {
  border-bottom: 4px solid #FFCC00 !important;
}

.nav-item :focus .tab-item-img1 {
  display: none;
}

.nav-item :focus .tab-item-img2 {
  display: block;
}

.main-image {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.button-cont {
  margin-left: 3rem;
}

.bharat-logo {
  /* width: 20%; */
}

.user-name-img {
  padding-top: 1rem;
  padding-bottom: 1rem;

  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 1rem;
}

.user-name-img p {
  margin: 0 !important;
  padding-left: 1rem;
}

.profile-menu h5 {
  padding: 10px;

}

.profile-menu img {
  padding: 10px;

}

.trip-preview-box {
  padding: 10px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}

.bottom-book-details {
  text-decoration: underline;
  color: #0A3874;
}

/* Styles for mobile and tablet views */
@media (max-width: 1024px) {
  .sticky-bottom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    z-index: 1049 !important;
  }

  .sticky-bottom-navbar2 {
    position: fixed;
    bottom: 78px;
    left: 0;
    width: 100%;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    /* align-items: center; */
    padding: 10px;
    z-index: 1049 !important;
  }

  .mob-nav-item {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tab-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ADADAD !important;
    padding: 0.4rem !important;

  }
}



/* Partner CSS */
.partner-banner {
  background-image: url("/public/assets/img/partner-banner.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  height: 690px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5rem;
}

.partner-banner-title {
  font-size: 3rem !important;
  text-align: center;
  color: white;
  font-weight: 800;
  line-height: 5.2rem;
}

.partner-banner-title span {
  color: #FFCC00;
}

.partner-banner-content h3 {
  color: #fff;
  margin: 1rem;

}

.partner-banner-content p {
  color: #fff;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.partner-banner-btn {
  background-color: #FFCC00 !important;

  border-radius: 1.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-weight: bold;
  color: #0A3874 !important;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.learn-more-btn {
  background-color: transparent;
  color: #FFCC00;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  border: 1px solid #FFCC00;
  border-radius: 2rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.learn-more-btn:hover {
  color: #0A3874;
  background-color: #FFCC00;
  border-color: #FFCC00;
}

.button-row-partner {
  /* width: 35% !important; */

}

.vision {
  background-image: url("/public/assets/img/vision-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  /* height: 690px; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 5rem;
}

.visionrow {
  /* width: 70% !important; */
}

.vision-text-head {
  color: #0A3874;
  font-weight: 800;
  font-size: 3.5rem;
}

.vision-text-p {
  font-size: 1.6rem;
}

.vision-text-p span {
  color: #0A3874;
  font-weight: 600;
}

.why-bharat-choice {
  color: #E7EBF1;
  font-size: 3.5rem;
  font-weight: 700;
}

.why-carousel-wrapper {
  width: 100vw;
  background-color: #072852;
  padding: 5rem;
}

.why-bharat-choice-p {
  color: #E7EBF1;
  font-size: larger;
}

.services-wrapper {
  background-image: url("/public/assets/img/blogs-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem;
  width: 100vw;
}

.services-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.services-section h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: bolder;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.certfied-head h4 {
  color: #0A3874 !important;
  font-size: 2.2rem !important;
  font-weight: bolder;

}

.airplane {
  position: absolute;
  right: 0;
}

.certified-text {
  color: #383838;
  margin-top: 2rem;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  padding-right: 5rem;
  line-height: 2rem;
}

.checklist-head {
  color: #0A3874 !important;
  font-size: 2rem !important;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.custom-bullet {
  list-style-type: none;
}

.custom-bullet li:before {
  content: "";
  display: inline-block;
  width: 24px;

  height: 24px;
  padding: 1rem;

  background-image: url("/public/assets/img/CheckCircle.svg");

  background-repeat: no-repeat;
  background-size: contain;
  /* margin-right: 10px; */

}

.checklist-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: -2.5rem !important;
  font-size: 1.5rem;
}

.program-wrapper {
  width: 100vw !important;
  background-color: #FFEFB0;
  padding: 1rem
}

.program-section {
  margin-top: 4rem;
  margin-bottom: 4rem;

}

.program-section h2 {
  color: #6B5600 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.program-intro {
  color: #383838;
  font-size: 1.3rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.program-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.program-cards p {
  color: #797979;
}

.dropdown-toggle::after {
  display: none;
}

.partner-btn {
  background-color: #FFCC00 !important;
  font-weight: bolder;
  border-radius: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  color: #0A3874 !important;
  display: inline-block;
}

.program-text h1 {
  color: #6B5600;
  font-weight: bolder;
  font-size: 4rem;
}

.program-text p {
  color: #383838;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.why-bharat-slide {
  border-radius: 2rem !important;
  overflow-y: hidden;
  height: 25rem;
  width: fit-content;
}

.swiper-nav-btns {
  margin-top: 1vw;
  margin-bottom: 1vw;
}

@media (max-width: 1024px) {
  .partner-banner {
    background-image: url("/public/assets/img/partner-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    height: 690px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5rem;
  }

}

@media only screen and (max-width: 500px) {
  .stay-connect span {
    font-size: 2rem !important;
    margin: 0rem 1rem 0rem 1rem;
    color: white;
    font-weight: bolder;
    line-height: 4rem;
    text-align: center;
    display: block;
  }

  .why-bharat-slide {
    border-radius: 2rem !important;
    height: 33rem;
    overflow-y: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 94%;
    margin-top: 21px !important;
    padding: 14px !important;
  }

  .checklist-item {
    text-align: left !important;
    display: flex;
    font-size: 16px !important;
    margin-bottom: 0.5rem;
    margin-left: -2.5rem !important;
    margin-top: 0.5rem;
  }

  .width44 {
    width: 100%;
  }

  .product-cards {
    border: 1px solid #d6d6d6;
    border-radius: 1.5rem;
    margin: 1.5rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: -webkit-fill-available;
    overflow-x: hidden;
    WIDTH: 100%;
  }

  .partner-banner {
    background-image: url("/public/assets/img/partner-banner-mob.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5rem;
  }

  .partner-banner-title {
    font-size: 2rem !important;
    text-align: center;
    color: white;
    font-weight: 800;
    line-height: 4.2rem;
  }

  .logodiv {
    justify-content: center !important;
  }

  .partner-banner-content p {
    color: #fff;
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .partner-banner-content h3 {
    color: #fff;
    font-size: 1.4rem;

  }

  .vision {
    background-image: url("/public/assets/img/vision-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    /* height: 690px; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 5rem;
  }

  .why-bharat-choice {
    font-size: 2.5rem;
  }

  .airplane {
    position: relative;
    top: 0;
  }

  .program-text h1 {
    color: #6B5600;
    font-weight: bolder;
    font-size: 3rem;
  }

  .program-text p {
    color: #383838;
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }


}

@media only screen and (max-width: 1000px) {
  .vision {
    background-image: url("/public/assets/img/vision-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    /* height: 690px; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 5rem;
  }

  .logodiv {
    justify-content: center !important;
  }
}

/* Product Level 1 CSS */
.sign-up-btn {
  background-color: #FFCC00 !important;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.search-button {
  background-color: #FFCC00 !important;

  border-radius: 1.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-weight: bold;
  color: #0A3874 !important;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.product-banner {
  /* background-image: url("/public/assets/img/ProductLevel-web-banner.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 450px; */


}

.blogs-wrapper {
  background-image: url("/public/assets/img/blogs-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem;
  width: 100vw;
}

.product-banner-title {
  font-size: 5rem !important;
  margin: 0rem 4rem 0rem 4rem;
  color: white;
  font-weight: 800;
  line-height: 5.5rem;

}

.product-banner-title span {
  color: #FFCC00;
}

.booking-form {
  max-width: 35rem;
  padding: 1.5rem !important;
  border-radius: 1rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: 4rem !important;
}

.form-element-style {
  border: none !important;
}

.form-element-each {
  border: 0.50px #ADADAD solid;
  padding: 0.5rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border-radius: 1rem;

}

.form-element-each span {
  display: block;
  margin-left: 0.7rem;
  color: #797979 !important;
}

.form-element-each.check-in {
  border: 0.50px #ADADAD solid;
  padding: 0.5rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;

}

.form-element-each.check-out {
  border: 0.50px #ADADAD solid;
  padding: 0.5rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;

  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.tabscont {
  /* width: 105% !important; */
}

.tab-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ADADAD !important;
  padding: 0.1rem !important;

}

.tab-heads {
  display: flex;
  justify-content: space-around;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.nav-link {}

.nav-link.active {
  border-bottom: 4px solid #FFCC00 !important;
  color: #000 !important;
  font-weight: 500;
}

.welcome-section {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.welcome-section h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.intro {
  color: #000;
  font-size: 1.3rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.welcomecards h5 {
  font-weight: bolder;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.welcomecards p {
  color: #797979;
}

.blogs-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.blogs-section h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.blog-text {}

.blog-text h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.blog-intro {
  font-size: 1.3rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.blogs-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.blogs-cards p {
  color: #797979;
}

.events-wrapper {
  width: 100vw !important;
  background-color: #FFEFB0;
  padding: 1rem
}

.events-section {
  margin-top: 4rem;
  margin-bottom: 4rem;

}

.events-section h2 {
  color: #6B5600 !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.events-intro {
  color: #383838;
  font-size: 1.3rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.events-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.events-cards p {
  color: #797979;
}

.experience-wrapper {
  width: 100vw !important;
  background-color: #061F40;
  padding: 1rem
}

.experience-section {
  margin-top: 4rem;
  margin-bottom: 4rem;

}

.experience-cards {
  background-color: #E7EBF1;
  border-radius: 1rem;
  padding: 1rem;

}

.experince-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}

.experience-section h2 {
  color: #E7EBF1 !important;
  font-size: 3rem !important;
  font-weight: bolder;
  margin-bottom: 2rem;
}

.experience-intro {
  color: #383838;
  font-size: 1.3rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.experience-cards h5 {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.experience-cards p {
  color: #797979;
}

.cards-inner-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.review-text {
  padding: 1rem;
  padding-bottom: 1rem;
}

.see-more-btn {
  background-color: transparent;
  color: #FFCC00;
  margin: 2rem;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  border: 2px solid #FFCC00;
  border-radius: 2rem;
  font-weight: 700;
}

.see-more-btn:hover {
  color: #0A3874;
  background-color: #FFCC00;
  border-color: #FFCC00;
}

.partnership h2 {
  color: #0A3874 !important;
  font-size: 3rem !important;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

}

.partnership {
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-width: 1520px !important;
  width: 100vw !important;
}

.cta-wrapper {

  background-image: url("/public/assets/img/cta-bg-web.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0px -600px; */
  padding: 1rem;
  width: 100vw;
}

.subscribe-btn {
  background-color: #FFCC00 !important;
  font-weight: bolder;
  border-radius: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;

  display: inline-block;
}

/* .stay-connect span {
  font-size: 2.4rem !important;
  margin: 0rem 1rem 0rem 1rem;
  color: white;
  font-weight: bolder;
  line-height: 4rem;
  text-align: start;
  display: block;
} */

.cta {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.sub-row-cont {
  background-color: #fff;
  border-radius: 2.5rem;
  padding-left: 0.2rem !important;
  padding-right: 3rem !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.email-input :focus {
  outline: none;
}

.email-input {
  border: none;
}

.footer-wrapper {
  padding-top: 2rem;
  /* padding-bottom: 5rem; */
  background-color: #061F40;
  width: 100vw;
}

.footer-row {
  justify-content: space-evenly;
}

.copright-txt {
  margin-top: 0.5rem;
  color: #fff;
}

.liststyles {
  text-align: left;
}

.liststyles ul li {
  list-style-type: none;
  text-decoration: none;
  color: #fff;
  padding: 0.2rem;
}

.liststyles span {
  color: #fff;
}

.payment span {
  color: white;
  display: block;
}

.pay-icon {
  margin: 0.2rem;
}

.list-head {
  font-weight: bold !important;
  font-size: 1.2rem;
}

.slides {
  display: flex;
  justify-content: center;
}

.tab-item-img {
  width: 35px;
}

/* Styles for desktop view */
.sticky-bottom-navbar {
  display: none;
}

.search-sticky {
  display: none;
  position: fixed;
  top: -100px;
  /* Initially hidden */
  left: 0;
  width: 100%;
  background-color: #ffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  z-index: 999;
  transition: top 0.3s ease;
  /* Add transition effect */
}

.search-sticky.visible {
  display: flex;
  padding: 1rem;
  background-color: #fff;
}

.tab-item-img1 {
  display: block;
}

.tab-item-img2 {
  display: none;
}

.nav-item :focus .tab-item-img1 {
  display: none;
}

.nav-item :focus .tab-item-img2 {
  display: block;
}

.bharat-logo {
  /* width: 20%; */
}

.sort-by {
  border: none !important;
  width: fit-content;
  padding: 1rem;
}

.sort-by-title {
  font-weight: 550;
  padding-right: 2rem;
  margin-left: 1rem
}

.product-row {
  display: flex !important;
}

.product-cards {
  border: 1px solid #d6d6d6;
  border-radius: 1.5rem;
  margin: 1.5rem;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fill-available;
  overflow-x: hidden;
}

.product-card-content {
  padding: 1rem;
}

.product-card-content h5 {
  font-weight: 550;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.product-tags {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.product-tags span {
  background-color: #EBEBEB;
  font-size: 12px;
  margin-right: 10px;
  padding: 0.1rem 0.5rem;
  border-radius: 8px;
  color: #858585;
}

.price span {
  font-weight: 500;
}

/* Styles for mobile and tablet views */
@media (max-width: 1024px) {
  .sticky-bottom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    z-index: 999;
  }

  .search-sticky {
    display: none;
  }

  .mob-nav-item {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tab-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ADADAD !important;
    padding: 0.4rem !important;

  }
}

@media only screen and (max-width: 500px) {
  .product-banner-title {
    font-size: 3.5rem !important;
    margin: 0rem 4rem 0rem 4rem;
    color: white;
    font-weight: 800;
    line-height: 4.5rem;
    text-align: center !important;

  }

  .width44 {
    width: 100% !important;
  }

  .certfied-head h4,
  .services-section h2 {
    color: #0a3874 !important;
    font-weight: bolder;
    text-align: center !important;
  }


  .certified-text {
    color: #383838;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-right: 0rem !important;
    text-align: center !important;
  }

  .product-cards {
    border: 1px solid #d6d6d6;
    border-radius: 1.5rem;
    margin: 1.5rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: -webkit-fill-available;
    overflow-x: hidden;
    WIDTH: 100%;
  }

  .blogs-wrapper {

    padding: 0rem !important;

  }

  .bookingbannermobile {
    background-image: url("/public/assets/img/mobile-banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .bookingbannermobilehotel {
    /* background-image: url("/public/assets/img/mobile-banner-hotel-booking.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .partnership h2 {
    color: #0A3874 !important;
    font-size: 2rem !important;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;

  }


  .cta-wrapper {

    background-image: url("/public/assets/img/cta-bg-mobile.png");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
    text-align: center;
  }

  .header-nav {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .payment {
    margin-left: 2rem;
  }

  .subscribe-btn {
    background-color: #FFCC00 !important;
    font-weight: bolder;
    border-radius: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    display: inline-block;
  }

  .sub-row-cont {
    margin-top: 2rem;
  }

  .search-button {
    width: 100%;
  }

  .tab-item-img {
    width: 32px;
  }

  .mobile-nav {
    display: flex;
  }
}

@media only screen and (max-width: 1000px) {
  .search-button {
    width: 100%;
  }

  .product-banner-title {
    font-size: 3rem !important;
    margin: 4rem 2rem 4rem 2rem;
    color: white;
    font-weight: 800;
    line-height: 4rem;
    text-align: left;

  }

  .booking-form {
    width: 100vw !important;
    padding: 1rem !important;
    border-radius: 1rem;
    margin-top: -1rem !important;
    margin-bottom: 0rem !important;

  }

  .mobile-nav {
    display: flex;
  }

  .cta-wrapper {

    background-image: url("/public/assets/img/cta-bg-tab.png");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
  }

  .footer-wrapper {

    padding-bottom: 5rem !important;

  }
}

/* Wedding and events */
.hero-banner {
  width: 100vw !important;
}

.herocarouselslide {
  /* background-image: url("/public/assets/img/herobannerbg-web.svg"); */
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15vw;
  padding-bottom: 15vw;
}

.herocarouselslide h1 {
  font-family: 'Parisienne',
    cursive;
  color: #FFCC00;
  font-size: 5rem;
}

.herocarouselslide h3 {

  color: #FFF;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.slider-container {
  margin: 1rem;
}


.herocarouselslide span {
  font-family: 'Parisienne',
    cursive;
  color: #fff;
}

.herobanner-text p {
  color: #FFF;
  font-size: 1.5rem;
  text-align: center;
}

.herobanner-text {
  text-align: center;
  position: relative;
  z-index: 2;
}

.getintouchbtn {
  background-color: #FFCC00;
  color: #0A3874;
  font-weight: bold;
  border-radius: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.getintouchbtn:hover {

  color: #FFCC00;
  font-weight: bolder;
  border-radius: 3vw;

  border: 1px solid #FFCC00;
}

.lifelong-slide {
  border-radius: 2rem !important;
  /* overflow-y: hidden; */
  height: 20rem;
  width: fit-content;
  margin: 1vw;
}

.lifelong-slide h4 p {
  color: #fff !important;
}

.lifelong-text {
  color: #E7EBF1;
  font-size: 3.5rem;
  font-weight: 700;
}

.lifelong-carousel-wrapper {
  width: 100vw;
  background-color: #072852;
  padding: 5rem;
}

.lifelong-text-p {
  color: #E7EBF1;
  font-size: 2rem;
}

.lifelong-content {}

.button-cont {
  margin-top: 8vw;
}

.venue-cards {
  overflow-x: hidden;

}

.unique-slide {
  border-radius: 2rem !important;
  padding-top: 0 !important;
  height: 20rem;
  width: fit-content;
  margin: 1vw;
}

.unique-slide h4 p {
  color: #fff !important;
}

.unique-text {
  color: #6B5600;
  font-size: 3.5rem;
  font-weight: 700;
}

.unique-carousel-wrapper {
  width: 100vw;
  background-color: #FFEFB0;
  padding: 5rem;
  position: relative;
}

.unique-text-p {
  color: #6B5600;
  font-size: 2rem;
}

.unique-content {
  padding: 1vw;
}

.cta-events-wrapper {

  background-image: url("/public/assets/img/cta-events-bg-web.webp");
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem;
  width: 100vw;
}

.blog-card-img-box {
  border-radius: 1rem;
  width: 400px;
  height: 350px;

  margin-right: 10px;


}

.product-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-img {
  border-radius: 1rem;
}

.cta-events-cont {
  padding-top: 6vw;
  padding-bottom: 6vw;
}

.cta-events-text h3 {
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.offering {

  background-color: #FFCC00;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
}

.offering:hover {

  color: #FFCC00;
  font-weight: bolder;
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid #FFCC00;

}

.appointment {

  color: #FFCC00;
  font-weight: bolder;
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid #FFCC00;
  margin-top: 1rem;
}

.appointment:hover {

  background-color: #FFCC00;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 3vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.venue-img {
  padding: 1rem;
}

.venue-card-text {
  padding: 1rem;
}

.venue-card-text h4 {
  font-weight: 550;

}

.venue-card-text p {
  color: #797979;
}

.top-right-leaf {
  position: absolute;
  right: 0px;

}

.mid-left-leaf {
  position: absolute;
  left: 0px;
}

.bottom-right-leaf {
  position: relative;
  right: 0px;

}

#unique-slider {
  order: 1;
}

#unique-cont {
  order: 2;
}

.cus-dinner-text.desktop {
  border-bottom: 0.50px #ADADAD solid;
  margin: 0 !important;
}

@media only screen and (max-width: 500px) {
  .cta-events-wrapper {

    background-image: url("/public/assets/img/cta-events-bg-mob.webp");
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
  }

  .lifelong-text-p {
    color: #E7EBF1;
    font-size: 1rem;
  }

  .cta-events-text h3 {
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .mid-left-leaf {
    padding: 2rem;
  }

  .bottom-right-leaf {
    position: relative;
    right: -20px;

  }

  .unique-text-p {
    color: #6B5600;
    font-size: 1.5rem;
  }

  #unique-slider {
    order: 2;
  }

  #unique-cont {
    order: 1;
  }

  .herocarouselslide {
    background-image: url("/public/assets/img/herobannerbg-mob.svg");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 1vw;
    padding-bottom: 1vw; */
  }
}

@media only screen and (max-width: 1000px) {
  .herocarouselslide {
    background-image: url("/public/assets/img/herobannerbg-tab.svg");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15vw;
    padding-bottom: 15vw;
  }

  .cta-events-wrapper {

    background-image: url("/public/assets/img/cta-events-bg-tab.webp");
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
  }

  .lifelong-text-p {
    color: #E7EBF1;
    font-size: 1.5rem;
  }

  .cta-events-text h3 {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .unique-text-p {
    color: #6B5600;
    font-size: 1rem;
  }

  #unique-slider {
    order: 2;
  }

  #unique-cont {
    order: 1;
  }
}

/* M.I.C.E CSS */
.mice-banner {
  background-image: url("/public/assets/img/herobannerbg-web.svg");
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15vw;
  padding-bottom: 15vw;
}

.herocarouselslide h1 {

  color: #FFCC00;
  font-size: 5rem;
}

.herocarouselslide h3 {

  color: #FFF;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.slider-container {
  margin: 0px !important;
  padding: 0px !important;
}


.herocarouselslide span {
  font-family: 'Parisienne',
    cursive;
  color: #fff;
}

.mice-banner-text p {
  color: #FFF;
  font-size: 1.5rem;
  text-align: center;
}

.mice-banner-text {
  text-align: center;
  position: relative;
  z-index: 2;
}

.btn-container-mice {
  position: relative;
  z-index: 2;
}

.mice-outer {
  width: 100vw !important;
}



.micebanner {
  background-image: url("/public/assets/img/herobannerbg-web.svg");
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem;

}



.mice-banner-text h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #FFF;
}

.mice-banner-text p {
  color: #FFF;


}

.micecarouselslide h1 {
  color: #FFF;
}

.yellow-span {
  color: #FFCC00;
}

.service-offer-btn {

  background-color: #FFCC00;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;

}

.service-offer-btn:hover {

  color: #FFCC00;
  font-weight: bolder;
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid #FFCC00;

}

.mice-get-in-btn {

  color: #FFCC00;
  font-weight: bolder;
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid #FFCC00;
  margin-top: 1rem;
}

.mice-get-in-btn:hover {

  background-color: #FFCC00;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 3vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.getintouchbtn {
  background-color: #FFCC00;
  color: #0A3874;
  font-weight: bold;
  border-radius: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.getintouchbtn:hover {

  color: #FFCC00;
  font-weight: bolder;
  border-radius: 3vw;

  border: 1px solid #FFCC00;
}

.lifelong-slide {
  border-radius: 2rem !important;
  /* overflow-y: hidden; */
  height: 20rem;
  width: fit-content;
  margin: 1vw;
}

.lifelong-slide h4 p {
  color: #fff !important;
}

.lifelong-text {
  color: #E7EBF1;
  font-size: 3.5rem;
  font-weight: 700;
}

.lifelong-text-dest {
  color: #E7EBF1;
  font-size: 3.5rem;
  font-weight: 700;
}

.lifelong-carousel-wrapper {
  width: 100vw;
  background-color: #072852;
  padding: 5rem;
}

.lifelong-text-p {
  color: #E7EBF1;
  font-size: 2rem;
}

.lifelong-content {
  margin-top: 1rem;
  width: 80%;
}

.button-cont {
  margin-top: 8vw;
}

.venue-cards {
  overflow-x: hidden;

}

.unique-slide {
  border-radius: 2rem !important;
  /* overflow-y: hidden; */
  height: 20rem;
  width: fit-content;
  margin: 1vw;
}

.unique-slide h4 p {
  color: #fff !important;
}

.unique-text {
  color: #6B5600;
  font-size: 3.5rem;
  font-weight: 700;
}

.unique-carousel-wrapper {
  width: 100vw;
  background-color: #FFEFB0;
  padding: 5rem;
}

.unique-text-p {
  color: #6B5600;
  font-size: 2rem;
}

.unique-content {
  padding: 1vw;
}

.cta-events-wrapper {

  background-image: url("/public/assets/img/cta-events-bg-web.webp");
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem;
  width: 100vw;
}

.cta-events-cont {
  padding-top: 6vw;
  padding-bottom: 6vw;
}

.cta-events-text h3 {
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}



.venue-img {
  border-radius: 2rem;
}

.venue-card-text {}

.venue-card-text h4 {
  font-weight: 550;

}

.venue-card-text p {
  color: #797979;
}

.top-right-leaf {
  position: absolute;
  right: 0px;

}

.mid-left-leaf {
  position: absolute;
  left: 0px;
}

.bottom-right-leaf {
  position: relative;
  right: 0px;

}

#unique-slider {
  order: 1;
}

#unique-cont {
  order: 2;
}

.partner-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-image: url(./../public/assets/img/herobannerbg-mob.svg); */
  /* Adjust the alpha (last) value to control darkness */
}

.overlay-banner {
  position: absolute;
  top: 6.5rem;
  left: 0;
  width: 100%;
  height: 40rem;
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 500px) {
  .cta-events-wrapper {

    background-image: url("/public/assets/img/cta-events-bg-mob.webp");
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
  }

  .lifelong-text-p {
    color: #E7EBF1;
    font-size: 1rem;
  }

  .lifelong-text-dest {
    color: #E7EBF1;
    font-size: 3.2rem;
    font-weight: 700;
  }

  .cta-events-text h3 {
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .mid-left-leaf {
    padding: 2rem;
  }

  .bottom-right-leaf {
    position: relative;
    right: -20px;

  }

  .unique-text-p {
    color: #6B5600;
    font-size: 1.5rem;
  }

  #unique-slider {
    order: 2;
  }

  #unique-cont {
    order: 1;
  }

  .micecarouselslide {
    background-image: url("/public/assets/img/herobannerbg-mob.svg");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 1vw;
    padding-bottom: 1vw; */
  }
}

@media only screen and (max-width: 1000px) {
  .micecarouselslide {
    background-image: url("/public/assets/img/herobannerbg-tab.svg");

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15vw;
    padding-bottom: 15vw;
  }

  .cta-events-wrapper {

    background-image: url("/public/assets/img/cta-events-bg-tab.webp");
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
  }

  .lifelong-text-p {
    color: #E7EBF1;
    font-size: 1.5rem;
  }

  .cta-events-text h3 {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .unique-text-p {
    color: #6B5600;
    font-size: 1rem;
  }

  #unique-slider {
    order: 2;
  }

  #unique-cont {
    order: 1;
  }
}

/* Form */
.sub-btn-form {
  background-color: #FFCC00 !important;

  border-radius: 1.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-weight: bold;
  color: #0A3874 !important;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.canc-btn-form {
  background-color: transparent !important;
  border: 1px solid #FFCC00;
  border-radius: 1.5rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-weight: bold;
  color: #FFCC00 !important;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.canc-btn-form:hover {

  border: 1px solid #FFCC00;
  color: #0A3874;
  font-weight: bolder;
  border-radius: 3vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

/* Product Level 2 */

.ratings img {
  padding: 0.25rem;

}

.ratings span {
  display: block;
  padding: 0.25rem;
}

.hotel-header h3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #000;
}

.amenties {
  display: block;
  padding: 0.25rem;
}

.booking-dialog-box {
  border: 0.5px #ADADAD solid;
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
  background-color: #FFF;
  margin-top: 2rem;
  /* margin-right: -10rem;
  margin-left: 12rem; */
}

.booking-text1 li {
  list-style-type: none;
  font-size: 0.8rem;
}

.blue-colr {
  color: #0A3874 !important;
}

.wp-available {
  padding: 8px 2px;
  background: #ed1c24;
  text-align: center;
  border-radius: 3px;
}

.wp-available h6 {
  font-size: 1rem;
  color: #fff;
}

/* Price breakup */
.pay-now {
  -webkit-box-shadow: 0px 10px 15px 0px rgba(224, 224, 224, 0.31);
  -moz-box-shadow: 0px 10px 15px 0px rgba(224, 224, 224, 0.31);
  box-shadow: 0px 10px 15px 0px rgba(224, 224, 224, 0.31);
  padding: 30px 15px;
  border-radius: 13px;
  margin-bottom: 20px;
  background: #fff;
  width: 130%;
}

.pay-now h5 {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #00709a;
  border-bottom: 1px solid #00709a;
  font-weight: 500;
}

.pay-now h3 a {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 13px;
  color: #ed1c24;
}

.pay-now ul {
  list-style-type: disc;
  padding: 2px 0 0 20px;
}

.pay-now ul li {
  font-size: 17px;
  color: #666;
  line-height: 21px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 300;
}

.pay-now ul li span {
  color: #666;
}

.pay-now ul li i {
  color: #000 !important;
  font-size: 12px;
}

.amount-price {
  height: auto;
}

.amount-price h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.amount-price h4 span {
  color: #666;
  font-size: 16px;
  text-align: right;
  float: right;
  font-weight: 600;
  color: #000;
}

.amount-price hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.amount-price .btn-secondary {
  color: #000 !important;
  background-color: #6c757d00;
  border-color: #6c757d00;
  padding: 0px !important;
  font-size: 16px !important;
}

.pay-now p {
  font-size: 17px;
  color: #666;
  margin-bottom: -14px;
  font-weight: 300;
}

.pay-now .form-control {
  height: calc(2.5rem + 2px);
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
}

.pay-now .btn-primary {
  color: #fff;
  background-color: #00a3c8;
  border-color: #00a3c8;
  border-radius: 0px;
  padding: 10px 16px;
}

.totalrow {
  border-top: 0.50px #ADADAD solid;
  padding-top: 1rem;
}

.booking-card-cont {
  position: relative;
  border: 0.50px #ADADAD solid;
  margin-right: 2rem;
  border-radius: 2rem;
  z-index: 1019 !important;
}

.left-col-det {
  /* margin-right: 2rem !important; */
}

.firstcol {
  padding: 2rem;
  border-right: 0.50px #ADADAD solid;
}

.firstcol h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #0A3874;
}

.column-head {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: block;
  font-size: large;
  font-weight: 600;
  border-right: 0.50px #ADADAD solid;
  border-bottom: 0.50px #ADADAD solid;
}

.column-head2 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: block;
  font-size: large;
  font-weight: 600;
  /* border-right: 0.50px #ADADAD solid; */
  border-bottom: 0.50px #ADADAD solid;
}

.roomdetails-box {
  padding: 1rem;
  border-right: 0.50px #ADADAD solid;
  border-bottom: 0.50px #ADADAD solid;
}

.bottom-box {
  border-bottom: none !important;
  padding-bottom: 5rem !important;
}

.roompricing-box {
  padding: 1rem;
  /* border-right: 0.50px #ADADAD solid; */
  border-bottom: 0.50px #ADADAD solid;
  /* border-bottom-right-radius: 2rem; */
}

.room-type-head {
  font-size: 1.5rem;
  font-weight: 550;
}

.amenties img {
  padding: 10px;
}

.form-element-select {
  border: 0.50px #ADADAD solid;
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
}

@media only screen and (min-width: 769px) {
  .extra-bed {
    font-size: 12px;
    padding-left: 5rem !important;
  }
}




.wish-list-btn {
  color: #FFCC00;
  font-weight: 600;
  font-size: 1.25rem;
  padding: 1rem;
}

.location-details-wrapper {
  width: 100vw;
  position: relative;
  background-color: #fff;
}

/* book now screen */
.book-now-card {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
}

.book-now-paybutton {
  width: 100%;
  border: none;
  background-color: #FFCC00;
  border-radius: 1rem;
  color: #0A3874;
}

.book-now-paybutton :hover {
  width: 100%;
  border: none;
  background-color: #FFCC00 !important;
  border-radius: 1rem;
  color: #0A3874;
}

.sticky {}

.sticky-re {}

/* calender open */

input[type="date"] {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Personal Details */
.prsnl-dtl-btn {

  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 2rem;
}

.canc-txt {
  color: #FFCC00;
  font-weight: bold;
}

.sav-txt {
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
}

.sav-btn {
  background-color: #D9D9D9;
  border: none;
}

.brdr-btm-grey {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 0.5rem;
}

.social-sign-in {
  background-color: #fff;
  border: 1px #808080 solid;
  padding: 0.5rem 0.2rem;
  border-radius: 2rem;
  width: 100%;
}

.preview-swiper img {
  /* height: 12vw; */
  width: 12vw;
  object-fit: cover;
}

.social-sign-in:hover {
  background-color: #fff !important;
  border: 1px #808080 solid !important;

}

.btn-see-more {
  position: absolute;
  right: 118px;
  top: 691px;
  background-color: #fff;
  color: #FFCC00;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  width: fit-content;
  border: 1px solid #FFCC00;
}

.btn-see-more :hover {

  background-color: #fff !important;
  color: #FFCC00 !important;

}

.slide-image-size {
  width: 100%;
}

.main-box {
  /* border-radius: 1rem; */
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.main-box-img {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.img-fluid.hotel-more-img {
  margin-left: 10px;

}

/* .welcome-card-img-cont {
  width: 400px;
  height: 287px;
  object-fit: cover;
  border-radius: 1rem;
} */

.image-box-more {
  width: 21vw;
  height: 16.5vw;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;

}

.image-box-more img {
  object-fit: cover;
  width: 20vw !important;
  height: 15.5vw !important;
  /* width: 100% !important; */
  /* height: 100% !important; */
}

.main-box img {
  height: 33.9vw;
}

.images-container {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 95vw;
}

.image-box-more-car {
  /* width: 900px;
  height: 350px; */
  padding: 2rem;
  border-radius: 1rem;
}

.img-fluid.hotel-more-img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.hotel-more-img {
  object-fit: cover;



}



.swiper-img-box {
  overflow: visible;
}

.swiper-see-more-img {}

/* Food Preferences Page */

.user-details-col h4 {
  color: #0A3874;
  font-weight: 600;
}

.radio-select {
  width: 3rem !important;
  height: 2rem !important;
  -webkit-text-size-adjust: none !important;
}

.preference-radio input[type=radio] {
  width: 20px !important;
  height: 20px !important;
  background: #fff;
}

/* jhhgh */

.cus-dinner-img img {
  border-radius: 6px;
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.prices.mt-2 {
  text-align: right;
  /* padding: 0 5px; */
}

div#field2 {
  text-align: right;
  -webkit-text-size-adjust: none !important;
}

.cus-dinner-img a {
  font-size: 14px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  margin-top: 15px;
  display: block;
}

.cus-dinner-text h3 {
  font-size: 20px;
  color: #0A3874;
  padding: 0.5rem;
  position: relative;
  font-weight: 700;
}

.cus-dinner-box-text h6 {
  color: #464646 !important;
  font-size: 17px;
  margin-bottom: 7px;
}

.cus-dinner-box {
  border-right: 0.50px #ADADAD solid;
  height: 100%;
  margin-left: 10px;

}

.cus-dinner-box1 {
  border-right: 0.50px #ADADAD solid;
  height: 140px;
}




.cus-dinner-box-text p {
  font-size: 14px;
}

.cus-dinner-box-text .hotel-text4 h4 {
  color: #000;
  font-size: 20px;
  line-height: 37px;
  font-weight: 600;
}

.cua-border hr {
  border-top-width: 1px;
  border-top: 1px solid rgb(141 198 63);
  margin-top: 40px;
}

.hotel-text1 a {
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
}

.hotel-text3 i {
  color: #000;
}

.hotel-text1 {
  /* padding: 0px 15px 0 12px !important; */
}

p.free.free1 span {
  color: #01aaef;
  display: inline-block;
}

p.free.free1 span img {
  margin-bottom: 0px;
}

.free1 i {
  margin-right: 0px !important;
  color: #8ec642;
}

.box-dispaly {
  display: flex;
  flex-wrap: wrap;
}

.cuss-room {
  border-bottom: 1px solid #006c95;
  width: 316px;
}

.cuss-room1 {
  border-bottom: 1px solid #006c95;
  width: 331px;
}

.cuss-room2 {
  border-bottom: 1px solid #006c95;
  width: 315px;
}

.hotel-text3 {
  /* padding: 8px 0 0 15px !important; */
}

.prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prices.mt-2 {
  text-align: right;
  /* padding: 0 5px; */
}

.welcome-card-img-cont img {
  border-radius: 1rem;
  height: 220px;
  object-fit: cover;
  width: 345px;
}

.hotel-text2 h6 {
  font-size: 16px;
  color: #000;
  display: inline-block;
  float: none;
  padding: 5px 4px 12px 0px;
  background: #fff;
}

.prices h6 {
  font-size: 14px;
  color: #000;
  display: inline-flex;
  float: left;
  align-items: baseline;
  background: #fffdfd;


}

.price button#sub2 {
  background: #d2cece !important;
}

.prices input {
  width: 60px;
  background: #fffdfd;

  font-size: 19px;
  color: #000;
  /* margin-bottom: 8px; */
}

input.adult_value {
  width: 35px !important;
}

input.field {
  width: 35px;
}

.font-fix {
  font-weight: 300 !important;
  font-size: 15px !important;
}

.qty1 {
  display: inline-block;
  margin-left: 20px;
}

.hotel-text1 h6 span {
  color: #000;
  margin-left: 5px;
  font-size: 18px;
}

.prices h6 span {}

.services-box {
  /* width: 55%; */
  margin-top: 1rem;
  margin-right: 2rem;
  position: relative;
  /* padding: 1rem; */
  border: 0.50px #ADADAD solid;
  margin-bottom: 1rem;
  border-radius: 2rem;

}

.ful-height {
  height: 85% !important;
  /* padding: 1rem; */
}

.border-add {
  border-right: 0.50px #ADADAD solid;
}

.set_size {
  font-size: 25px !important;
  /* line-height: 30px; */
  width: 1.75rem !important;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: #000 !important;

  text-align: center !important;

  text-align: center;
  border-radius: 0;
  background-clip: padding-box;
  background: #d2cece !important;
  -webkit-text-size-adjust: none !important;
}

button#add {
  margin-left: -5px;
}

#field1 button {
  /* width: 34px !important; */
  font-size: 29px;
  padding: 8px 0 15px 0 !important;
  line-height: 14px;
  color: #fff !important;
  background: #ffffff !important;
  border: 1px solid #d9d6d6;
  height: 30px;
  color: #7b7a7a !important;
  margin-right: -5px;
}

#field2 input {
  background: #fff !important;
  color: #000 !important;
  height: 30px;
  border: 1px solid #d9d6d6;
  text-align: center;
  -webkit-text-size-adjust: none !important;
}

#field1 input {
  background: #fff !important;
  color: #000 !important;
  height: 30px;
  border: 1px solid #d9d6d6;
  text-align: center;
  -webkit-text-size-adjust: none !important;
}

div#field2 {
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-size-adjust: none !important;
}

button#sub2 {
  /* width: 30px !important; */
  font-size: 29px;
  /* padding: 6px 2px 20px 0 !important; */
  /* line-height: 18px; */
  color: #fff !important;
  background: #ffffff !important;
  border: 1px solid #d9d6d6;
  height: 30px;
  color: #7b7a7a !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: -5px; */

  -webkit-text-size-adjust: none !important;



}

button#add2 {
  /* width: 30px !important; */
  font-size: 29px;
  /* padding: 8px 0 15px 0 !important; */
  /* line-height: 15px; */
  color: #fff !important;
  background: #fff !important;
  border: 1px solid #d9d6d6;
  height: 30px;
  color: #7b7a7a !important;
  /* margin-left: -5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-size-adjust: none !important;
}

.login-cont- {
  /* border: 0.50px #ADADAD solid; */

  /* margin-top: 10%; */
}

.don {
  display: block;

}

.dont {
  display: flex;
}

.tesitmonial-image-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 1rem;
}

.prices input {
  width: 60px;
  background: #fffdfd;

  font-size: 19px;
  color: #000;
  /* margin-bottom: 8px; */
}



input.field {
  width: 25px !important;
}

.qty1 {
  display: inline-block;
  margin-left: 20px;
}

/* Blogs Screen */
.blogs-p {
  /* width: 70%; */
}

@media (min-width: 768px) {
  .blogs-p {
    width: 70%;
    /* Additional styles for web */
  }
}

.table-overflow {
  overflow-x: scroll;
}

.modal-cont {
  /* background-color: transparent !important;
  width: 100vw;
  height: 100vh; */
}

.blogs-banner {
  /* background-image: url("/public/assets/img/blogs-main-banner.png"); */
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;
}

.city-blogs-banner {
  background-image: url("/public/assets/img/city-blogs-banner.svg");
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 372px;
}

.weddings-blogs-banner {
  background-image: url("/public/assets/img/wedding-blogs-banner.svg");
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 465px;
}


.view-all-butt {
  background-color: #FFCC00;
  color: #0A3874;
  border: none;
  font-weight: 600;
  border-radius: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mice-blogs-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.mice-blogs-section h2 {
  color: #fff !important;
  font-size: 3rem !important;
  font-weight: bolder;
}

.intro {
  color: #000 !important;
  font-size: 1.3rem !important;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
}

.intro-white {
  color: #fff !important;
  font-size: 1.3rem !important;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
}

.welcomecards h5 {
  font-weight: bolder;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.welcomecards p {
  color: #797979;
}

.welcomecards {
  overflow-x: hidden;
  margin-bottom: 20px;

}

.mice-blogs-wrapper {
  width: 100vw;
  background-color: #0A3874;
}

.mice-card-text {
  padding: 1rem;
}

.mice-card-text h4 {
  font-weight: 550;
  color: #fff;

}

.mice-card-text p {
  color: #fff;
}

.destination-slides {
  /* background-image: url("/public/assets/img/destination-img.png"); */
  /* background-color: #061F40 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 284px;
}

.details-text-box {
  padding-top: 1rem;
  width: 100%;
}

.product-image-slide {
  width: 100%;

  display: flex;
  justify-content: center;

}

:root {
  --swiper-navigation-size: 25px !important;
}

.product-slide-cards {

  overflow-x: hidden;
  border-radius: 1.5rem;
  max-width: fit-content;
  margin: 1.5rem;
}

.product-card-content {
  padding: 1rem;
}

.product-card-content h5 {
  font-weight: 550;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.product-tags {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.product-tags span {
  background-color: #EBEBEB;
  margin-right: 10px;
  padding: 0.3rem 1.4rem;
  border-radius: 8px;
  color: #858585;
}

.price span {
  font-weight: 500;
}

.prod-img {
  border-radius: 1rem;
  max-width: 100%;

}

.img-container {}

.img-container h2 {
  font-size: 35px;
  color: #0a3874;
  font-weight: 800;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 25px;
  position: relative;
}

.img-container h2:before {
  content: '';
  background: #ffcc00;
  height: 6px;
  position: absolute;
  left: 1px;
  width: 33%;
  border-radius: 5px;
  bottom: -15px;
}

.img-container p {
  color: #737373;
  font-size: 16px;
  text-align: left;
  margin-bottom: 30px;
  font-weight: 500;
}

.img-container-box {
  border-radius: 1rem;
  height: 300px;
  margin-bottom: 35px;
  overflow: hidden;
  position: relative;
  width: auto;
  margin-right: 10px;
}

.img-container-box img {
  object-fit: cover;
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-backface-visibility: hidden;
  height: 330px;
  border-radius: 1rem;

}

.img-container-box:hover img {
  /* -webkit-transform: scale(1.3);
  transform: scale(1.3); */
}

.img-container-box-text {
  position: absolute;
  /* z-index: 99999; */
  bottom: 0px;
  padding: 16px;
  width: 100%;

  background: linear-gradient(0deg, rgba(2, 0, 36, 1) 10%, rgba(99, 94, 94, 0.218) 90%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.img-container-box p {
  color: #fff;
}

.img-container-box h3 {
  color: #fff;
}

.specialrow {
  display: flex !important;
  /* justify-content: center !important; */

}

.custom-file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.custom-file-input label {


  color: rgb(232, 186, 0);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.custom-file-input input[type="file"] {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.summary-box {
  border-radius: 1.5rem;
  height: 10%;
}

.ul-list {
  list-style: none;
  /* Remove default list-style */
  padding: 0;
  /* Remove default padding */
}

.ul-list-li {
  margin-left: 20px;
  /* Adjust the space between the icon and the text */
  position: relative;
  /* Create a relative positioning context for the icons */
  display: flex;
  align-items: center;
}

.ul-li-i {
  position: absolute;
  left: -20px;
}


.image-modal {
  /* height: 100vh !important;
  width: 100vw !important; */
  --bs-modal-zindex: 2055;
}

/* CustomModal.css */
.custom-img-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.custom-img-modal .modal-content {
  border: none;
  border-radius: 0;
}

.custom-img-modal .modal-body {
  padding: 0;
}

.custom-img-modal .modal-header {
  padding: 15px;
}

.image-modal .modal-dialog {
  display: flex;
  align-items: center;
  max-width: 100vw;
  height: 100vh;
}

.image-modal .modal-content {
  /* background-color: transparent !important; */

  /* height: 55vh;
  margin-top: 7rem; */

}

.partner-carousel-img-cont img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 1rem;
}

.content-column {
  height: min-content !important;
}

.sticky-contt {
  min-height: 100% !important;
  /* Set an appropriate minimum height */
}

.table-contt {
  overflow: visible !important;
}

@media only screen and (max-width: 500px) {
  .booking-banner-title {
    font-size: 3.5rem !important;
    margin: 0rem !important;
    color: white;
    font-weight: 800;
    line-height: 4.5rem;
    text-align: center !important;

  }

  .form-element-style {
    border: none !important;
  }

  .form-element-style1 {
    border: none !important;
    background-color: #F3F3F3 !important;
  }

  .mobile-bg-col {
    background-color: #F3F3F3;
    border-radius: 10px;
    margin: 15px;
  }

  .mobile-realign-ul {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .mobile-realign-ul-mrgin {
    margin-bottom: 0 !important;
    align-items: center;
  }

  .roomdetails-box {
    padding: 1rem;
    border-right: none;
    border-bottom: none;
  }

  .roompricing-box {
    border-bottom: none;
  }

  .sm-justify-content-center {
    justify-content: center;
  }

  .homebanner-cont {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mobile-banner-title-col {
    height: 35vh;
  }

  .why-carousel-wrapper {
    padding: 1rem;
  }

  .vision {
    padding: 1rem;
  }

  .services-wrapper {
    padding: 0rem;
  }

  .welcomecards {
    /* display: flex;
    justify-content: center;
    background-color: pink; */
    margin-bottom: 10px;
  }

  .welcomecards p {
    /* width: 40%; */
    /* background-color: pink; */
  }

  .firstcol h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #0A3874;
  }

  .specialrow {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
  }

  .events-cards {
    margin-right: 0rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .unique-carousel-wrapper {
    padding: 1.5rem;
  }

  .spinner-modal .modal-content {
    width: 100vw !important;
  }

  .image-modal .modal-dialog {
    display: flex;
    align-items: center;
    max-width: 100vw !important;
  }

  .image-modal.modal-dialog {
    display: flex;
    align-items: center;
    max-width: 100vw !important;
  }

  .welcome-section {
    /* margin-top: 27rem !important; */
  }

  .find-best {
    /* margin-top: 28rem !important; */
  }

  .booking-card-cont {
    position: relative;
    border: 0.50px #ADADAD solid;
    margin-right: 0rem !important;
    border-radius: 2rem;
    z-index: 1019 !important;
  }

  .ratings img {
    padding: 0.2rem;

  }

  .amenties {
    display: block;
    padding: 0.15rem;
    font-size: 14px;
  }

  .ratings span {
    display: block;
    padding: 0.15rem;
    font-size: smaller;
  }

  .img-container-box img {
    margin-bottom: 35px;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    width: 330px !important;
    height: 250px !important;
    object-fit: cover;
  }

  .img-container-box {
    margin-bottom: 35px;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    width: 330px !important;
    height: 250px !important;
  }

  .bookingbannermobile {
    /* background-image: url("/public/assets/img/mobile-banner1.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .moreimages {
    display: none;
  }

  .main-box-img {
    border-radius: 1rem;
    width: 100%;
    height: 57.9vw !important;
    object-fit: cover;
  }


  .btn-see-more {
    top: -55px !important;
    left: 175px !important;
    position: relative !important;
    z-index: 999;

    background-color: #fff;
    color: #FFCC00;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #FFCC00;
  }

  .booking-banner {
    /* background-image: none !important; */
    padding: 0 !important;
    height: 50rem;



  }

  .overlay-banner {
    position: absolute;
    top: 0rem;
    left: 0;
    width: 100%;
    height: 50rem;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .tab-heads {
    padding: 0 !important;
  }

  .cta-wrapper {

    background-image: url("/public/assets/img/cta-bg-mobile.png");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
    text-align: center;
  }

  .header-nav {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .payment {
    margin-left: 2rem;
    margin-bottom: 8rem;
  }

  .don {
    display: none !important;
  }

  .dont {
    display: none !important;
  }

  .subscribe-btn {
    background-color: #FFCC00 !important;
    font-weight: bolder;
    border-radius: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    display: inline-block;
  }

  .sub-row-cont {
    margin-top: 2rem;
  }

  .search-button {
    width: 100%;
  }

  .tab-item-img {
    width: 25px !important;
  }

  .mobile-nav {
    display: flex;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0 !important;
  }

  .nav {
    flex-wrap: nowrap !important;
  }

  /*  */
  .room-box h4 {
    font-size: 23px;
    font-weight: 700;
    color: #00aeef;
  }


  .hotel-text1 .border-bottom {
    border-bottom: 1px solid #fff !important;
    background: #ffffff;
  }

  .border-left {
    border-left: 1px solid #ffffff !important;
  }

  .hotel-text1 p i {
    font-size: 11px !important;
    padding: 0px;
  }

  .hotel-text1 p {
    font-size: 11px !important;
    margin-bottom: 0px !important;
    line-height: 20px;
  }

  .room-box p.campan {
    width: 100%;
    margin-top: 9px;
    font-size: 17px;
  }

  .prices h6 {
    font-size: 10px;
    color: #21b8f1;
    display: inline-flex;
    float: none;
    /* padding: 5px 0px 12px 0px; */
    background: #fffdfd00;
    font-weight: 500;
    width: 99%;
    justify-content: center;
    margin-top: 0px;
    padding: 0px;
  }

  .prices h6 span {}

  .qty {}

  #field1 {}

  .prices input {
    width: 58px;
  }

  .prices {
    margin-top: 0px !important;
  }

  .hotel-text3 {
    padding: 0px 0 0 8px !important;
  }

  .cus-food-text h5 {
    line-height: 15px;
    text-align: center;
  }

  .hei {
    height: auto;
  }

  .cus-search-form .dropdown-menu #sub2 {
    background: #ffffff !important;
  }

  .cus-search-form .dropdown-menu #add2 {
    background: #ffffff !important;
  }

  /*  */

  .services-box {
    width: 95% !important;
    margin-left: 1rem !important;
    margin-left: 1rem !important;
    position: relative;
    /* padding: 1rem; */
    border: 0.50px #ADADAD solid;
    margin-bottom: 1rem;
    border-radius: 2rem;

  }

  .lifelong-carousel-wrapper {
    width: 100vw;
    background-color: #072852;
    padding: 1rem;
  }

}

@media only screen and (max-width: 1000px) {
  .search-button {
    width: 100%;
  }

  .product-banner-title {
    font-size: 3rem !important;
    margin: 4rem 2rem 4rem 2rem;
    color: white;
    font-weight: 800;
    line-height: 4rem;
    text-align: left;

  }

  .booking-form {
    width: 100vw !important;
    padding: 1rem !important;
    border-radius: 1rem;
    margin-top: -1rem !important;
    margin-bottom: 0rem !important;

  }

  .mobile-nav {
    display: flex;
  }

  .cta-wrapper {

    background-image: url("/public/assets/img/cta-bg-tab.png");
    /* background-color: #061F40 !important; */
    background-repeat: no-repeat;
    background-size: cover;

    padding: 1rem;
    width: 100vw;
  }

  .footer-wrapper {

    padding-bottom: 5rem !important;

  }
}

@media only screen and (max-width: 380px) {
  .hotel-text1 h6 span {
    color: #000;
    margin-left: 2px;
    font-size: 14px;
  }

  .why-bharat-slide {
    border-radius: 2rem !important;
    height: 33rem;
    overflow-y: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 94%;
    margin-top: 21px !important;
    padding: 14px !important;
  }

  .why-bharat-slide {
    border-radius: 2rem !important;
    height: 33rem;
    overflow-y: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 94%;
    margin-top: 21px !important;
    padding: 14px !important;
  }

  .checklist-item {
    text-align: left !important;
    display: flex;
    font-size: 16px !important;
    margin-bottom: 0.5rem;
    margin-left: -2.5rem !important;
    margin-top: 0.5rem;
  }

  .width44 {
    width: 100%;
  }

  .product-cards {
    border: 1px solid #d6d6d6;
    border-radius: 1.5rem;
    margin: 1.5rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: -webkit-fill-available;
    overflow-x: hidden;
    WIDTH: 100%;
  }

  button#sub2 {
    /* width: 25px !important; */
    font-size: 19px;
    /* padding: 6px 2px 20px 0 !important; */
    /* line-height: 18px; */
    color: #fff !important;
    background: #ffffff !important;
    border: 1px solid #d9d6d6;
    height: 30px;
    color: #7b7a7a !important;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-size-adjust: none !important;
    /* margin-right: -5px; */
  }

  #field2 input {
    background: #fff !important;
    color: #000 !important;
    height: 30px;
    border: 1px solid #d9d6d6;
    text-align: center;
    width: 30px !important;
    -webkit-text-size-adjust: none !important;
  }

  .select-style-fix {
    -webkit-text-size-adjust: none !important;
  }

  button#add2 {
    /* width: 25px !important; */
    font-size: 19px;
    /* padding: 8px 0 15px 0 !important; */
    /* line-height: 15px; */
    color: #fff !important;
    background: #fff !important;
    border: 1px solid #d9d6d6;
    height: 30px;
    color: #7b7a7a !important;
    /* margin-left: -5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-size-adjust: none !important;
  }

  .btn-see-more {
    top: -37px !important;
    left: 150px !important;
    position: relative !important;
    z-index: 999;
    background-color: #fff;
    color: #FFCC00;
    font-weight: bold;
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #FFCC00;
    font-size: small;
  }
}

.width44 {
  width: 35%;
}


.mx-auto {
  margin-right: auto !important;
}


.details-text-box img {
  width: 100%;
  /* max-width: 1000px; */
  max-height: 400px;
  object-fit: cover;
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .details-text-box img {
    max-width: 500px;
    max-height: 300px;
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* Ensure it covers the full viewport width */
  height: 100%;
  /* Ensure it covers the full viewport height */
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Ensure modal is above other content */
}

.modal-content {
  position: relative;
  /* background: white; */
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  /* Adjust as needed */
  max-height: 95vh;
  /* Adjust as needed */
  overflow: hidden;
  /* Prevents overflow of content */
}

.modal-image {
  max-width: 100%;
  max-height: 85vh;
  /* Adjust as needed */
  object-fit: contain;
  /* Ensures image scales properly */
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 22px;
  /* background: red; */
  color: black;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
}

.faq-toggle-icon {
  font-size: 18px;
  font-weight: bold;
}



/* ActivityTicket.css */
.activity-form {
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.activity-form .form-group {
  margin-bottom: 1.5rem;
}

.activity-form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

.activity-form .form-control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
}

.activity-form .btn {
  background: #007bff;
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  color: #fff;


}

.activity-form .btn:hover {
  background: #0056b3;
}

.activity-form h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.activity-form .form-group input:focus,
.activity-form .form-group select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.container.about-us-section {
  max-width: 600px;
  margin: 0 auto;
}


.kep-login-facebook {
  -webkit-font-smoothing: antialiased;
  background-color: #4c69ba;
  border: calc(.06887vw + .67769px) solid #4c69ba;
  border-radius: 4px !important;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Helvetica, sans-serif;
  font-size: calc(.27548vw + 12.71074px);
  font-size: 14px !important;
  font-weight: 700;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s, border-color .3s;
  width: auto !important;
}