.whatsapp-icon {
    background: #25d366;
    /* WhatsApp green color */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    /* Circular shape */
    padding: 15px;
    position: fixed;
    right: 25px;
    bottom: 20%;
    z-index: 999;
    transition: background 0.3s ease-in-out;
}

.whatsapp-icon:hover {
    background: #128c7e;
    /* Darker shade on hover */
}

.whatsapp-icon a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.whatsapp-icon img {
    width: 30px;
    height: 30px;
    border-radius: 20%;
    /* Circular image */
}